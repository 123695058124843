<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 80%;display: flex;"></div>
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large"
						@click="append"><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button>
				</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:empty-text="texts" :header-cell-style="{ background: '#F5F7FA' }" @row-click="unit">
				<!-- 短信模板编号  -->
				<el-table-column fixed label="短信模板编号" prop="templateCode" align="center" :show-overflow-tooltip='true' width="180">
				</el-table-column>
				<!-- 短信内容  -->
				<el-table-column label="短信内容 " prop="messageContent" align="center" :show-overflow-tooltip='true' width="400">
				</el-table-column>
				<!-- 是否存在变量 -->
				<el-table-column label="是否存在变量 " prop="isVariable" align="center" :show-overflow-tooltip='true' :formatter="formatSex" width="120">
				</el-table-column>
				<!-- 短信变量模板 -->
				<el-table-column label="短信变量模板" prop="messageVariable" align="center" :formatter="quantum" width="400"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 创建时间 -->
				<el-table-column label="创建时间  " prop="createTime" align="center" :show-overflow-tooltip='true' width="180"
					:formatter="reach">
				</el-table-column>
				<!-- 是否发布 -->
				<el-table-column label="是否发布 " prop="isRelease" align="center" :show-overflow-tooltip='true' :formatter="formatSex1" width="120">
				</el-table-column>
				<!-- 发布人姓名 -->
				<el-table-column label="发布人姓名" prop="adminName" align="center" :show-overflow-tooltip='true' width="180">
				</el-table-column>
				<!-- 备注 -->
				<el-table-column label="备注" prop="reason" align="center" :show-overflow-tooltip='true' width="300">
				</el-table-column>
				<!-- 操作 -->
				<el-table-column label="操作" align="center" :show-overflow-tooltip='true' width="150">
					<template #default="scope">
						<el-button size="default" @click.stop="copyreader(scope.row.id)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="单位名称" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />

				<!-- <div v-for="(item,index) in treeData" :key="index" @click="dendrogram(item)">
		  {{item.unitName}}
		</div> -->
			</s3-layer>
		</div>
		<!-- 删除按钮弹窗 -->
		<!-- <el-dialog v-model="remove" title="动态删除" width="30%" center>
			<div class="labor">
				<div class="sure">确定删除此动态吗？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" size="large" @click="remove=false">取消</el-button>
				</span>
			</template>
		</el-dialog> -->
	</div>
</template>
<script>
	import {
		WalletFilled,
		Van,
		ShoppingCartFull,
		Search,
		Download,
		Plus,
		EditPen,
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount,
		onActivated
	} from 'vue';
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		// PFintegral,
		// ZClist,
		// FYintegral,
		// FPtotal,
		smsTemplate
	} from "../../../utils/api";
	import {
		useStore
	} from "vuex";
	import qs from 'qs';
	import moments from 'moment'
	// import moments from 'moment'
	export default {
		name: "smsTemplate",
		computed: {},
		setup() {
			const route = useRoute();
			// 搜索按钮加载
			let formative = ref(false)
			// 成功弹窗
			let anew = ref(false)

			let quoth = ref('')

			// 下拉隐藏
			let selectIt = ref()
			let closeIt = () => {
				selectIt.value.blur();
			}

			// 加载中
			const acting = ref(false)
			// 按钮禁用
			const jinyong = ref(false)
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					unitAbbreviation.value = ''
				}
			}

			// 导入
			// const error=(e)=>{
			//   console.log(e)
			// }
			const pleased = ref()




			// 数据总共条数
			let zong = ref(null)
			// let reach = (row, colum, cellValue) => {
			// 	if (typeof cellValue == 'number') {
			// 		return moments(cellValue * 1000).format().substring(0, 10)
			// 	}
			// }
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			// 判断是否存在变量
			let formatSex = (row, column, isVariable) => {
				if (isVariable == 1) {
					return "是";
				} else if (isVariable === 2) {
					return "否";
				}
			};
			// 判断是否发布
			let formatSex1 = (row, column, isRelease) => {
				if (isRelease == 1) {
					return "是";
				} else if (isRelease === 2) {
					return "否";
				}
			};
			// 列表数据循环
			const filterTableData = ref([])
			// 表格数据接口
			let list = () => {
				// let data = {
				// 	limit: branches.value,
				// 	page: currentPage.value
				// }
				smsTemplate().then((res) => {
					if (res.data.code == 0) {
						// zong.value = res.data.data.refundIntegralPage.totalCount
						filterTableData.value = res.data.data.messageTemplateList
						console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 分页获取积分的派发日志
			// 动态加载数据
			let branches = ref(6)
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				console.log(size)
				pagesize.value = size
				branches.value = size
				if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
					.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				texts.value = '加载中'
				if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
					.value !== '') {
					search()
				} else {
					list()
				}
				// console.log(currentPage.value)
				// console.log(branches.value )
			}


			// 加载中
			let texts = ref('加载中')
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				// translate.value=route.params.userId
				if (translate.value == 1) {
					texts.value = '加载中'
					list()
					// 所有组织列表接口
					// lang()
				}
				// if (translate.value == 1) {
				// 	a.value = ''
				// 	b.value = ''
				// 	unitAbbreviation.value = ''
				// 	serial.value = ''
				// 	name.value = ''
				// 	texts.value = '加载中'
				// 	branches.value = 6
				// 	currentPage.value = 1
				// 	list()
				// 	// 所有组织列表接口
				// 	lang()
				// }
			})

			let search1 = () => {
				formative.value = true
				search()
			}


			// 搜索框点击
			let search = () => {
				// 时间截止
				// 工会code
				// console.log(Hcode.value)
				// 会员编码框值
				// console.log(serial.value)
				// 姓名
				// console.log(designation.value)
				console.log(a.value)
				console.log(b.value)
				// 搜索接口
				// let data = {
				// 	limit: branches.value,
				// 	page: currentPage.value,
				// 	// 开始时间 结束时间
				// 	startTime: a.value,
				// 	endTime: b.value,
				// 	// 工会标识
				// 	unitAbbreviation: unitAbbreviation.value,
				// 	username: serial.value,
				// 	name: designation.value,
				// }
				smsTemplate().then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						// zong.value = res.data.data.refundIntegralPage.totalCount
						filterTableData.value = res.data.data.messageTemplateList
						// console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					} else if (res.data.code !== 0) {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			let a = ref('')
			let b = ref('')

			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
				}
				// console.log(e)
			}

			// 搜索组织列表接口
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 标识
			let unitAbbreviation = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unitName
				unitAbbreviation.value = node.unitAbbreviation
				visible.value = false
				console.log(node)
			}
			let treeData = ref([])
			const yes1 = () => {
				form.coding = options.value.unionName
				visible1.value = false;
			};


			onMounted(() => {
				// 获取当前页path
				// lang()
				list()
			})
			// const lang = () => {
			// 	ZClist().then((res) => {
			// 		texture.value = res.data.data.unitList
			// 		//  console.log(res)
			// 	})
			// 	// console.log('44657687')
			// }
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				label: 'unitName',
			}
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 搜索组织弹窗
			let visible = ref(false)
			// 会员编码框
			let serial = ref('')
			// 功能类型
			const values = ref('')
			//状态
			const values1 = ref('')
			// 搜索框变化值
			let orangutan = () => {
				// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
				//   list()
				//   console.log('空了')
				// }
				// console.log('变化了')
			}
			// 姓名输入框值
			let designation = ref('')

			// 时间选择
			let value1 = ref('')
			const whirl = ref(false)
			onMounted(() => {
				// //选择工会下拉框数据
				// lang()
				//列表页数据
				list()
				// //头部积分数据
				// gration()
			})
			// 待办内容点击事件
			const router = useRouter()
			// 待办内容
			const tableData = [];
			// 头像加载失败
			const errorHandler = () => true;
			// 头部添加跳转
			let append = () => {
				console.log(111)
				router.push('/templateAdd')
			}
			// 编辑跳转
			const copyreader = (id) => {
				// console.log(id)
				sessionStorage.setItem('templateIds', id)
				router.push({
					name: 'templateEdit',
					params: {
						UserId: 1
					}
				})
			}
			return {
				formative,
				search1,
				quoth,
				anew,
				selectIt,
				closeIt,
				// error,
				jinyong,
				numerical,

				immediUpdate,
				reach,
				filterTableData,
				// 分页
				zong,
				branches,
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 组织弹窗
				ability,
				texture,
				visible,
				// yes,
				dendrogram,
				defaultProps,
				multiple,
				yes1,
				treeData,
				// 会员编码框
				serial,
				// 搜索框
				search,
				// 姓名框变化值
				orangutan,
				// 会员姓名
				designation,
				// 时间选择
				whirl,
				value1,
				errorHandler,
				tableData,
				texts,
				append,
				values,
				values1,
				copyreader,
				formatSex,
				formatSex1,
			};
		},
		components: {
			Search,
			WalletFilled,
			Van,
			ShoppingCartFull,
			Plus,
			EditPen,
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	.summation {
		display: flex;
		justify-content: space-between;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	.el-select--large {
		width: 20%;
		// margin-left: 10px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 20%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
<style>
	/* ::-webkit-scrollbar{display:none} */
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>